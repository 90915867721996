body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

.graphiql-container,
.CodeMirror-info,
.CodeMirror-lint-tooltip,
reach-portal {
  /* Colors */
  --color-primary: 238, 100%, 10%;
}
body.graphiql-dark .graphiql-container,
body.graphiql-dark .CodeMirror-info,
body.graphiql-dark .CodeMirror-lint-tooltip,
body.graphiql-dark reach-portal {
  --color-primary: 238, 100%, 10%;
}

@media (prefers-color-scheme: dark) {
  body:not(.graphiql-light) .graphiql-container,
  body:not(.graphiql-light) .CodeMirror-info,
  body:not(.graphiql-light) .CodeMirror-lint-tooltip,
  body:not(.graphiql-light) reach-portal {
  --color-primary: 238, 100%, 10%;
  }
}

#root {
  height: 100vh;
}

:root {
  --toastify-color-info: #000233;
}